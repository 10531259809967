<template>
  <div class="flex flex-col activity-detail">
    <div
      class="flex w-full min-h-50 max-h-screen/2"
      v-if="activity && activity.attributes"
    >
      <img class="object-cover w-full h-max" :src="imageUrl" />
    </div>
    <div
      class="min-h-50 px-4 lg:px-8 lg:w-screen-lg max-w-screen-lg mx-auto text-left space-y-6 mt-10"
      v-if="activity && activity.attributes"
    >
      <p class="title text-np-56 lg:text-np-3xl text-np-glimpse">
        {{ activity.attributes.title }}
      </p>
      <content-body :source="activity.attributes.body.content" />
    </div>
    <gallery-slide
      v-if="galleries && galleries.length > 0"
      :images="galleries"
      class="my-18 lg:my-20"
    />
    <share-items />
    <special-item
      v-if="activity && activity.attributes"
      title-list="other activities"
      type="experiences/activity"
      :subType="activity.attributes.type.data.id"
      :exclude-id="activity.id"
    />
  </div>
</template>

<script>
import { getActivityBySlug } from "@/api/endpoints/activities";
import to from "await-to-js";
import SpecialItem from "@/components/SpecialItem.vue";
import ShareItems from "@/components/ShareItems.vue";
import ContentBody from "../../components/ContentBody.vue";
import GallerySlide from "../../components/gallery/GallerySlide.vue";
import { getImageUrl } from "@/utils/imageUtils.js";

export default {
  name: "activityDetail",
  data() {
    return {
      lang: "en",
      activity_id: 0,
      activity: {},
      galleries: [],
    };
  },
  components: {
    SpecialItem,
    ShareItems,
    ContentBody,
    GallerySlide,
  },
  async mounted() {
    let activity_id = this.$route.params.activity_id;
    this.activity_id = activity_id;
    await this.setActivityDetail(this.lang, activity_id);
  },
  watch: {
    "$route.params.activity_id": {
      handler: async function (activity_id) {
        await this.setActivityDetail(this.lang, activity_id);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    imageUrl() {
      return this.activity.attributes.image.data.attributes.url;
      // if (this.activity.attributes.image.data.attributes.formats) {
      //   if (
      //     this.activity.attributes.image.data.attributes.formats.large &&
      //     this.activity.attributes.image.data.attributes.formats.large.url
      //   )
      //     return this.activity.attributes.image.data.attributes.formats.large.url;
      //   if (
      //     this.activity.attributes.image.data.attributes.formats.small &&
      //     this.activity.attributes.image.data.attributes.formats.small.url
      //   )
      //     return this.activity.attributes.image.data.attributes.formats.small.url;
      //   if (
      //     this.activity.attributes.image.data.attributes.formats.thumbnail &&
      //     this.activity.attributes.image.data.attributes.formats.thumbnail.url
      //   )
      //     return this.activity.attributes.image.data.attributes.formats.thumbnail
      //       .url;
      // }
      // return "";
    },
  },
  methods: {
    async setActivityDetail(lang, activityId) {
      const [err, response] = await to(getActivityBySlug(lang, activityId));

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }
      this.activity = response.data.data[0];
      if (this.activity.attributes.body.images) {
        this.galleries = this.activity.attributes.body.images.data.map(
          (img) => {
            return {
              id: img.id,
              image_url: getImageUrl(img),
            };
          }
        );
      } else {
        this.galleries = [];
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
}

.menu {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: #5c623f;
}

#experience-content {
  border-top: 1px solid #cecdc8;
}

.discover-more {
  background: #5c623f;
  color: white;
}

.description {
  font-style: normal;
  font-weight: 300;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
